// There is not a good way to test this file, so do not put logic here.
// It is here to make testing of the code that consumes the argo config
// from the window easier.
interface WindowWithArgoConfig extends Window {
    ARGO_CONFIG:any;
}

declare const window: WindowWithArgoConfig;

export function getArgoConfig() {
    return window.ARGO_CONFIG;
}